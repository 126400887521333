/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'

import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'

import { Main } from '@leshen/ui'

import {
  mapRichTextHero,
  mapSections,
} from '@leshen/gatsby-theme-contentful/utils/determiners'

import ZipPackageSection from '../components/ZipPackageSection'

const DynamicZipPackagesHSIMinimal = ({ data }) => {
  const {
    contentfulPage: { sections, hero, spanish },
  } = data

  const heroOptions = {
    mapper: mapRichTextHero,
    isImageCritical: true,
    spanish,
  }

  const sectionOptions = {
    spanish,
  }

  return (
    <Layout
      data={data}
      main={
        <Main>
          <div id="loadingScrollTarget" />

          <ZipPackageSection hsi hsiLogo={data.hsiLogoImage} />

          {/* Pass the hero to Leshens map so it behaves like normal when coming from Contentful */}
          {mapSections(hero, heroOptions)}

          {/* Render the rest of the sections from Contentful like normal */}
          {sections &&
            sections.map((section) => mapSections(section, sectionOptions))}
        </Main>
      }
    />
  )
}

export default DynamicZipPackagesHSIMinimal

export const query = graphql`
  query DynamicZipPackagesHSIMinimalQuery($id: String!) {
    hsiLogoImage: contentfulMedia(
      contentful_id: { eq: "48q86wrO9AUimwWUvBoM9s" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
